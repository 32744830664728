import React, {  } from 'react'



export const Test: React.FC = () => {

    console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV)
    console.log('process.env', process.env)

  return (
    <div className='page-container page-suppliers'>
      
      
      <div className='container main-container'>
                <div className='page-content'>
                   Test f
                   sfdsfdsf
                   dsf
                   ds
                   f
                   
                </div>
            </div>
      

    </div>
  )
}
